import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { postCampaignSubmission } from '../apis/api'

import phoneIcon from '../styles/icons/phone.png'
import personIcon from '../styles/icons/person.png'
import BackArrowIcon from '../styles/icons/back-arrow'
import ArrowRightIcon from '../styles/icons/arrow-right'
import Modal from '../components/modal/modal'

const Campaigns = () => {
  const access_token = localStorage.getItem('access_token')
  const user_data = localStorage.getItem('user_data')
  const parsed_user_data = JSON.parse(user_data)

  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [formData, setFormData] = useState({
    groupName: '',
    name1: '',
    phone1: '',
    name2: '',
    phone2: '',
    name3: '',
    phone3: '',
    name4: '',
    phone4: '',
    name5: '',
    phone5: '',
  })
  const [showTNC, setShowTNC] = useState(false)
  const [acceptTNC, setAcceptTNC] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const countEmptyValues = (arr) => {
    let count = 0
    arr.forEach(obj => {
      const values = Object.values(obj)
      const allEmptyStrings = values.every(value => value !== '')
      if (allEmptyStrings) {
        count++
      }
    })
    return count
  }

  const submitPayload = {
    access_token,
    email: parsed_user_data.email,
    groupName: formData.groupName,
    lists: [
      { fullName: formData.name1, phoneNumber: formData.phone1 },
      { fullName: formData.name2, phoneNumber: formData.phone2 },
      { fullName: formData.name3, phoneNumber: formData.phone3 },
      { fullName: formData.name4, phoneNumber: formData.phone4 },
      { fullName: formData.name5, phoneNumber: formData.phone5 },
    ]
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => postCampaignSubmission(payload),
    onSuccess: () => {
      if (countEmptyValues(submitPayload.lists) < 5) {
        setShowConfirm(true)
      } else {
        setShowConfirm(false)
        toast.success('Pendaftaran program BARU berhasil!')
        navigate('/')
      }
    },
    onError: (err) => {
      const errMessage = err.response.data.message
      toast.info(errMessage)
    },
  })

  const handleSubmit = () => {
    mutate(submitPayload)
  }

  return (
    <div
      style={{
        padding: isMobile ? 'initial' : '0 36.5%',
        backgroundColor: '#103856',
      }}
    >

      <Helmet>
        <title>BARU | Electrum Berbagi</title>
      </Helmet>

      <Modal
        closeBtn={false}
        isOpen={showConfirm}
      >
        <div style={{ fontSize: '1.5rem', fontWeight: '500', fontFamily: 'Gilroy-Bold', letterSpacing: '0.03rem', textAlign: 'center' }}>
          Upss! <br />
          Form Belum Lengkap
        </div>
        <div style={{ fontSize: '1rem', fontWeight: '500', fontFamily: 'Gilroy-SemiBold', letterSpacing: '0.03rem', textAlign: 'center', marginTop: '1.125rem' }}>
          Yuk, lengkapi formulir yang kamu isi, Gen-E!
        </div>
        <button
          style={{
            width: '100%',
            marginTop: '1.125rem',
            borderRadius: '0.5rem',
            border: 'none',
            backgroundColor: '#225E8B',
            fontFamily: 'Gilroy-SemiBold',
            fontSize: '0.875rem',
            color: 'white',
            padding: '0.65rem 0.75rem',
            cursor: 'pointer',
          }}
          onClick={() => setShowConfirm(false)}
        >
          Lanjut lengkapi
        </button>
        <div
          style={{
            width: '100%',
            marginTop: '1.125rem',
            fontFamily: 'Gilroy-Bold',
            fontSize: '0.875rem',
            color: '#225E8B',
            cursor: 'pointer',
            textAlign: 'center',
          }}
          onClick={() => {
            setShowConfirm(false)
            navigate('/')
          }}
        >
          Lewati
        </div>
      </Modal>

      {/* {isLoading && <Loader />} */}
      <div
        style={{
          height: '100vh',
          backgroundSize: 'cover',
          background: 'linear-gradient(180deg, rgba(194,251,255,1) 0%, rgba(92,170,214,1) 100%)',
          overflow: 'scroll',
        }}
      >

        {/* TITLE */}
        <div
          style={{
            background: 'linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(16,56,86,1) 100%)',
            borderRadius: '0 0 1rem 1rem',
            display: 'flex',
            alignItems: 'center',
            padding: '0 1.75rem',
          }}
        >
        <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          <BackArrowIcon />
        </div>
        <div
          style={{
            flex: 1,
            fontSize: '1.5rem',
            fontWeight: '500',
            color: 'white',
            fontFamily: 'Gilroy-ExtraBold',
            letterSpacing: '0.03rem',
            textAlign: 'center',
            padding: '1.75rem 0',
          }}
        >
          BARU
        </div>
        <div style={{ width: '24px' }} />
      </div>

        {/* FORM SECTION */}
        <div
          style={{
            margin: '1.5rem',
            backgroundColor: 'white',
            padding: '1.25rem 1rem',
            borderRadius: '0.625rem',
          }}
        >
          <div style={{ textAlign: 'center', marginBottom: '1.25rem', marginTop: '0.5rem', fontWeight: '500', color: '#103856',fontFamily: 'Gilroy-Bold', fontSize: '1.125rem' }}>
            Isi Formulir untuk Bergabung!
          </div>

          {/* KANDIDAT 1 */}
          <div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nama Teman ke-1
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='text'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='name1'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nomor Telefon Teman ke-1
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={phoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='number'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='phone1'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ borderBottom: '2px dotted #103856', marginBottom: '0.75rem' }} />
          </div>

          {/* KANDIDAT 2 */}
          <div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nama Teman ke-2
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='text'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='name2'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nomor Telefon Teman ke-2
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={phoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='number'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='phone2'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ borderBottom: '2px dotted #103856', marginBottom: '0.75rem' }} />
          </div>

          {/* KANDIDAT 3 */}
          <div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nama Teman ke-3
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='text'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='name3'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nomor Telefon Teman ke-3
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={phoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='number'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='phone3'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ borderBottom: '2px dotted #103856', marginBottom: '0.75rem' }} />
          </div>

          {/* KANDIDAT 4 */}
          <div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nama Teman ke-4
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='text'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='name4'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nomor Telefon Teman ke-4
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={phoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='number'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='phone4'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ borderBottom: '2px dotted #103856', marginBottom: '0.75rem' }} />
          </div>

          {/* KANDIDAT 5 */}
          <div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nama Teman ke-5
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='text'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='name5'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nomor Telefon Teman ke-5
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={phoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='number'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='phone5'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ borderBottom: '2px dotted #103856', marginBottom: '0.75rem' }} />
          </div>

          {/* GROUP NAME */}
          <div>
            <div style={{ marginTop: '1.25rem', marginBottom: '1rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nama Regu/Grup
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='text'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='groupName'
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* TNC CONTENT */}
          <div style={{ margin: '1.5rem 0' }}>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '0.5rem', cursor: 'pointer' }}
              onClick={() => setShowTNC(!showTNC)}
            >
              <div style={{ fontSize: '0.875rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Syarat dan Ketentuan
              </div>
              <div style={{ transform: showTNC ? 'rotate(270deg)' : 'rotate(90deg)', maxWidth: 'min-content' }}>
                <ArrowRightIcon />
              </div>
            </div>
            <div style={{ display: showTNC ? 'block' : 'none', fontSize: '0.75rem', marginBottom: '0.75rem',fontFamily: 'Gilroy-Medium' }}>
              Syarat dan ketentuan berikut ini (“<b>Syarat & Ketentuan</b>”) menguraikan peraturan dan ketentuan untuk berpartisipasi dalam kompetisi BARU - Bareng-bareng Lebih Seru (“<b>BARU</b>”) yang diselenggarakan oleh PT Energi Kreasi Bersama (“<b>Electrum</b>”).
              <br/><br/>
              Dengan mengisikan form ini, anda dianggap telah menerima dan menyetujui Syarat & Ketentuan ini.
              <br/><br/>
              <b>A. Aturan Umum</b>
              <br/><br/>
              1. Untuk berpartisipasi pada kompetisi BARU, anda harus:
              <br/>
              a). Merupakan suatu group yang berisikan 6 orang driver (termasuk anda sendiri) yang merupakan mitra aktif dari PT GoTo Gojek Tokopedia Tbk di area Jakarta (“<b>Group</b>”);
              <br/>
              b). Mengisi dan mensubmit formulir ini dengan benar dan lengkap.
              <br/><br/>
              2. Anda dengan ini menyatakan bahwa Anda berwenang dan telah memperoleh persetujuan dari rekan-rekan Anda dalam Group untuk memberikan data-data dalam form ini termasuk namun tidak terbatas pada data maupun informasi milik rekan-rekan anda dalam Group.
              <br/><br/>
              3. Anda dengan ini menyetujui bahwa data-data yang dibagikan dalam form ini dapat digunakan Electrum terkait dengan pengembangan layanan dan kegiatan usaha Electrum, termasuk untuk menghubungi Anda maupun rekan Anda dalam Group, pemberian informasi mengenai produk Electrum dan bentuk penawaran atau promosi lainnya. Ketentuan ini tetap berlaku setelah kompetisi BARU berakhir.
              <br/><br/>
              4. Dalam hal dinyatakan sebagai pemenang, Anda beserta rekan-rekan Anda dalam Group bersedia untuk hadir ke kantor Electrum dan memberikan data diri seperti KTP untuk keperluan verifikasi data diri.
              <br/><br/>
              5. Jangka waktu untuk berpartisipasi adalah sampai dengan tanggal 10 Mei 2024.
              <br/><br/>
              <b>B. Ketentuan Pemenang</b>
              <br/><br/>
              1. Akan terdapat 6 orang pemenang yang akan dipilih berdasarkan timestamp keikutsertaan (submission form) yaitu entri ke-1, ke-10, ke-100, ke-1000 dan ke-10.000. Apabila Anda melakukan submission form lebih dari satu kali, maka hanya submission form yang pertama yang akan dihitung dan dianggap sebagai keikutsertaan dalam kompetisi BARU.
              <br/><br/>
              2. Setiap Group yang terpilih menjadi pemenang akan menerima hadiah berupa uang sebesar Rp6.000.000 (6 juta rupiah) atau dapat berupa gratis sewa motor listrik Electrum H5/H3 dan gratis swapping baterai motor listrik senilai setara atau bentuk lain berdasarkan kebijakan Electrum.
              <br/><br/>
              3. Nama-nama pemenang akan diumumkan melalui Komunikasi GoJek dan akan dipublikasikan pada akun Instagram @electrum.id. Pemenang juga akan dihubungi melalui contact Whatsapp pribadi.
              <br/><br/>
              Electrum berhak untuk sewaktu-waktu mengubah Syarat & Ketentuan ini. Segala keputusan pemenang sepenuhnya merupakan wewenang dari Electrum dan merupakan keputusan yang tidak dapat diganggu gugat. 
              <br/><br/>
              <b>Catatan:</b><br />
              <a href='http://wa.me/6281284586636' target='_blank' rel='noreferrer' style={{ color: 'black', textDecoration: 'none' }}>
                Untuk permintaan informasi atau pertanyaan lebih lanjut, dapat menghubungi <i>Customer Service</i> di nomor WA <u>berikut</u>. Waspada penipuan! Program ini tidak dipungut biaya!
              </a>
              <br/><br/>
              <b>Persetujuan Syarat dan Ketentuan</b>
              <br/>
              Dengan ini, saya sudah mengerti dan menyetujui Syarat dan Ketentuan kompetisi BARU seperti yang tertera di atas
            </div>
            <div style={{ borderBottom: '2px solid #ECECEC', width: '100%' }} />
          </div>

          {/* AGREE TNC */}
          <div style={{ marginBottom: '1.25rem', display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" name="acceptTNC" value={acceptTNC} onClick={() => setAcceptTNC(!acceptTNC)} style={{ transform: 'scale(1.5)', marginRight: '1rem' }} />
            <label style={{ fontSize: '0.875rem', fontWeight: '500', color: '#888888', marginBottom: '0.25rem',fontFamily: 'Gilroy-Medium' }}>
              Saya Telah Membaca dan Menyetujui <b>Syarat dan Ketentuan</b>
            </label>
          </div>

          {/* BUTTON */}
          <button
            style={{
              cursor: acceptTNC && !isLoading ? 'pointer' : 'not-allowed',
              backgroundColor: acceptTNC && !isLoading ? '#225E8B' : '#EBEBEB',
              color: acceptTNC && !isLoading ? 'white' : '#A6A6A6',
              fontFamily: 'Gilroy-Bold',
              border: 'none',
              padding: '0.6rem',
              width: '100%',
              borderRadius: '7.5px',
              marginBottom: '0.5rem',
            }}
            onClick={handleSubmit}
            disabled={acceptTNC && !isLoading ? false : true}
          >
            { !isLoading ? 'Daftarkan Saya' : 'Loading...' }
          </button>
        </div>

      </div>
    </div>
  )
}

export default Campaigns
