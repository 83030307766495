import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Home,
  Winners,
  Campaign as CampaignOld,
  CampaignNew,
  SignIn,
  SignUp,
  SignUpCampaign,
  ForgotPIN,
  ResetPIN,
} from './pages'

const queryClient = new QueryClient()

const ProtectRoute = ({ element, ...rest }) => {
  const isAuthenticated = localStorage.getItem('access_token')
  return isAuthenticated ? element : <Navigate to="/signup-campaign" />
}

const App = () => {
  const routes = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/winners" element={<ProtectRoute element={<Winners />} />} />
          <Route path="/campaign-old" element={<ProtectRoute element={<CampaignOld />} />} /> {/* unused route */}
          <Route path="/campaign" element={<ProtectRoute element={<CampaignNew />} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signup-campaign" element={<SignUpCampaign />} /> {/* used for sharing */}
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgot-pin" element={<ForgotPIN />} />
          <Route path="/reset-pin/:resetToken" element={<ResetPIN />} />
        </Routes>
      </Router>
    )
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer
        autoClose={2000}
        position="top-center"
      />
      {routes()}
    </QueryClientProvider>
  )
}

export default App
