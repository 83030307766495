import axios from "axios"
import { ELECTRUM_API_URL } from "../consts"

export const postLogin = async(payload) => {
  const result = await axios.post(`${ELECTRUM_API_URL}/user/login`, payload)
  return result.data
}

export const postRegister = async(payload) => {
  const result = await axios.post(`${ELECTRUM_API_URL}/user/register`, payload)
  return result.data
}

export const postGetUserData = async(payload) => {
  const result = await axios.post(`${ELECTRUM_API_URL}/user/data`, payload)
  return result.data
}

export const putForgotPIN = async(payload) => {
  const result = await axios.put(`${ELECTRUM_API_URL}/user/forgot-pin`, payload)
  return result.data
}

export const patchResetPIN = async(payload) => {
  const { resetToken, pin } = payload
  const result = await axios.patch(`${ELECTRUM_API_URL}/user/reset-pin/${resetToken}`, { newPIN: pin })
  return result.data
}

export const postBooking = async(payload) => {
  const result = await axios.post(`${ELECTRUM_API_URL}/user/booking`, payload)
  return result.data
}
