import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import Modal from '../components/modal/modal'
import { Helmet } from 'react-helmet'
import { postBooking } from '../apis/api/user'

import winnerBanner from '../styles/images/winner-banner.png'
import cloudHome from '../styles/images/cloud_home_new.png'
import electrumBerbagiLogoNew from '../styles/images/electrum_berbagi_logo_new2.png'
import joinElectrum from '../styles/images/join_electrum.png'
import bikeElectrum from '../styles/images/bike_electrum_new.png'
import ArrowRightIcon from '../styles/icons/arrow-right'
import bikeIcon from '../styles/images/bike.png'
import successCheckIcon from '../styles/images/success_check.png'
import { PopupButton } from 'react-calendly'

const Home = () => {
  const access_token = localStorage.getItem('access_token')
  const user_data = localStorage.getItem('user_data')
  const parsed_user_data = JSON.parse(user_data)

  const navigate = useNavigate()

  const [modalOpenConfirmation, setModalOpenConfirmation] = useState(false)
  const [modalOpenSuccess, setModalOpenSuccess] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => postBooking(payload),
    onSuccess: () => {
      toast.success('Booking Test-drive Successful!')
      setModalOpenConfirmation(false)
      setModalOpenSuccess(true)
    },
    onError: (err) => {
      const errMessage = err.response.data.message
      toast.error(errMessage)
    },
  })

  const handleLogOut = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('user_data')
    navigate('/signin')
  }

  const capitalizeWords = (str) => {
    const words = str.split(' ')
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    return capitalizedWords.join(' ')
  }

  return (
    <div
      style={{
        padding: isMobile ? 'initial' : '0 36.5%',
        backgroundColor: '#103856',
      }}
    >

      <Helmet>
        <title>Beranda | Electrum Berbagi</title>
      </Helmet>

      <div
        style={{
          height: '100vh',
          backgroundSize: 'cover',
          background: 'linear-gradient(180deg, rgba(194,251,255,1) 0%, rgba(92,170,214,1) 100%)',
          overflow: 'scroll',
        }}
      >

        {/* CONFIRMATION MODAL */}
        <Modal
          closeBtn={false}
          isOpen={modalOpenConfirmation}
        >
          <div style={{ fontSize: '1.5rem', fontWeight: '500', fontFamily: 'Gilroy-Bold', letterSpacing: '0.03rem', textAlign: 'center' }}>
            Konfirmasi Test-drive
          </div>
          <div style={{ textAlign: 'center' }}>
            <img alt="bike_icon" src={bikeIcon} width={200} />
          </div>
          <div style={{ fontSize: '1rem', fontWeight: '500', fontFamily: 'Gilroy-SemiBold', letterSpacing: '0.03rem', textAlign: 'center' }}>
            Kamu akan mengajukan test-ride. <br />
            Klik untuk melanjutkan.
          </div>
          <button
            style={{
              width: '100%',
              marginTop: '1.125rem',
              borderRadius: '0.5rem',
              border: 'none',
              backgroundColor: !isLoading ? '#225E8B' : '#EBEBEB',
              fontFamily: 'Gilroy-SemiBold',
              fontSize: '0.875rem',
              color: !isLoading ? 'white' : '#A6A6A6',
              padding: '0.65rem 0.75rem',
              cursor: !isLoading ? 'pointer' : 'not-allowed',
            }}
            onClick={() => mutate({ access_token, bookTestDrive: true})}
          >
            { !isLoading ? 'Ya, ajukan test-ride' : 'Loading...' }
          </button>
          <div
            style={{
              width: '100%',
              marginTop: '1.125rem',
              fontFamily: 'Gilroy-Bold',
              fontSize: '0.875rem',
              color: '#225E8B',
              cursor: 'pointer',
              textAlign: 'center',
            }}
            onClick={() => setModalOpenConfirmation(false)}
          >
            Nanti Saja
          </div>
        </Modal>

        {/* SUCCESS MODAL */}
        <Modal
          closeBtn={false}
          isOpen={modalOpenSuccess}
        >
          <div style={{ fontSize: '1.5rem', fontWeight: '500', fontFamily: 'Gilroy-Bold', letterSpacing: '0.03rem', textAlign: 'center' }}>
            Test-drive Diajukan
          </div>
          <div style={{ textAlign: 'center', margin: '1.25rem 0' }}>
            <img alt="bike_icon" src={successCheckIcon} width={125} />
          </div>
          <div style={{ fontSize: '1rem', fontWeight: '500', fontFamily: 'Gilroy-SemiBold', letterSpacing: '0.03rem', textAlign: 'center' }}>
            Terima kasih, kami akan segera menghubungi anda!
          </div>
          <button
            style={{
              width: '100%',
              marginTop: '1.125rem',
              borderRadius: '0.5rem',
              border: 'none',
              backgroundColor: '#225E8B',
              fontFamily: 'Gilroy-SemiBold',
              fontSize: '0.875rem',
              color: 'white',
              padding: '0.65rem 0.75rem',
              cursor: 'pointer',
            }}
            onClick={() => setModalOpenSuccess(false)}
          >
            Kembali ke beranda
          </button>
        </Modal>

        {/* CONTENTS START HERE */}
        <div
          style={{
            backgroundImage: `url("${cloudHome}")`,
            backgroundSize: 'cover',
            borderRadius: '0 0 1rem 1rem',
            height: '21.5rem',
            imageResolution: '10000dpi',
          }}
        >
          {/* TOP SECTION */}
          <div style={{ padding: '2rem 1.75rem 0 1.75rem' }}>
            {/* LOGO */}
            <div style={{ textAlign: 'left' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', width: 'fit-content' }}>
                  <img
                    alt='electrum_logo'
                    src={electrumBerbagiLogoNew}
                    width={80}
                    style={{ marginRight: '0.5rem' }}
                  />
                </div>
                <div
                  style={{
                    fontSize: '0.875rem',
                    fontWeight: '500',
                    color: 'white',
                    fontFamily: 'Gilroy-Bold',
                    letterSpacing: '0.03rem',
                    textDecoration: 'underline',
                    height: 'fit-content',
                    padding: '0.5rem',
                    cursor: access_token ? 'initial' : 'pointer',
                  }}
                  onClick={access_token ? null : (() => navigate('/signin'))}
                >
                  {access_token ? `Hai, ${capitalizeWords(parsed_user_data.fullName)}` : 'Masuk'}
                </div>
              </div>
            </div>
            {/* TITLE & SUBTITLE */}
            <div style={{ fontSize: '1.125rem', fontWeight: '500', color: '#103856', fontFamily: 'Gilroy-ExtraBold', letterSpacing: '0.03rem', marginTop: '1.25rem' }}>
              Bersama Birukan Langit Indonesia
            </div>
            <div style={{ fontSize: '0.75rem', fontWeight: '500', color: '#103856', fontFamily: 'Gilroy-Bold', letterSpacing: '0.03rem', marginTop: '0.675rem' }}>
              Join the movement to raise awareness of the importance of clean energy for the planet
            </div>
          </div>

          {/* EVENTS SECTION */}
          <div style={{ marginTop: '1.5rem', padding: '0 1.75rem 1.5rem 1.75rem', display: 'flex', flexDirection: 'column', rowGap: '1.25rem' }}>
            {/* CAMPAIGN JOIN */}
            <div>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.125rem', color: '#103856', marginBottom: '0.5rem' }}>
                Info Terbaru
              </div>
              {/* <div
                style={{
                  cursor: 'pointer',
                  backgroundImage: `url("${joinElectrum}")`,
                  backgroundSize: 'cover',
                  height: '9rem',
                  padding: '1rem',
                  borderRadius: '0.625rem',
                  boxShadow: '0px 2px 15px 4px rgba(0,0,0,0.1)',
                }}
                onClick={() => access_token ? navigate('/campaign') : navigate("/signin")}
              /> */}
              <img
                src={joinElectrum}
                alt='join-electrum'
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  borderRadius: '0.625rem',
                  boxShadow: '0px 2px 15px 4px rgba(0,0,0,0.1)',
                }}
                onClick={() => access_token ? navigate('/campaign') : navigate("/signin")}
              />
            </div>
            {/* THR WINNER */}
            <div>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.125rem', color: '#103856', marginBottom: '0.5rem' }}>
                Cek Ini Juga
              </div>
              {/* <div
                style={{
                  cursor: 'pointer',
                  backgroundImage: `url("${winnerBanner}")`,
                  backgroundSize: 'cover',
                  height: '11rem',
                  borderRadius: '0.625rem',
                  boxShadow: '0px 2px 15px 4px rgba(0,0,0,0.1)',
                }}
                onClick={() => access_token ? navigate('/winners') : navigate("/signin")}
              /> */}
              <img
                src={winnerBanner}
                alt='winner-banner'
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  borderRadius: '0.625rem',
                  boxShadow: '0px 2px 15px 4px rgba(0,0,0,0.1)',
                }}
                onClick={() => access_token ? navigate('/winners') : navigate("/signin")}
              />
            </div>
            {/* BOOK A TEST DRIVE */}
            <div>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.125rem', color: '#103856', marginBottom: '0.5rem' }}>
                Jangan Lewatkan
              </div>
              <div
                style={{
                  backgroundImage: `url("${bikeElectrum}")`,
                  backgroundSize: 'cover',
                  // height: '100%',
                  height: '9rem',
                  padding: '1rem',
                  borderRadius: '0.625rem',
                  boxShadow: '0px 2px 15px 4px rgba(0,0,0,0.1)',
                }}
              >
                <div
                  style={{ fontSize: '1.25rem', fontWeight: '500', color: '#103856', fontFamily: 'Gilroy-ExtraBold', letterSpacing: '0.03rem', marginTop: '0.75rem', visibility: 'hidden' }}
                >
                  Kenyamanan <br />
                  Seutuhnya.<br />
                  <span style={{ color: '#40C0C0' }}>
                    Electrum H3 <br/>
                    dan H5.
                  </span>
                </div>
                <div style={{ display: 'flex', columnGap: '0.5rem', justifyContent: 'flex-end', marginTop: '-0.375rem' }}>
                  {/* <button
                    style={{
                      marginTop: '1.125rem',
                      borderRadius: '5rem',
                      border: 'none',
                      backgroundColor: '#225E8B',
                      fontFamily: 'Gilroy-SemiBold',
                      color: 'white',
                      padding: '0.35rem 0.75rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      fontSize: '0.75rem',
                    }}
                    onClick={() => access_token ? setModalOpenConfirmation(true) : navigate("/signin")}
                  >
                    Ajukan Test-drive &nbsp; <ArrowRightIcon />
                  </button> */}
                  <PopupButton
                    url="https://calendly.com/electrumindonesia/h3testride?back=1&month=2024-05"
                    rootElement={document.getElementById("root")}
                    text="Ajukan Test-drive"
                    pageSettings={{
                      hideGdprBanner: true,
                      hideLandingPageDetails: true,
                    }}
                    styles={{
                      marginTop: '1.125rem',
                      borderRadius: '5rem',
                      border: 'none',
                      backgroundColor: '#225E8B',
                      fontFamily: 'Gilroy-SemiBold',
                      color: 'white',
                      padding: '0.35rem 0.75rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      fontSize: '0.75rem',
                    }}
                  />
                  <button
                    style={{
                      marginTop: '1.125rem',
                      borderRadius: '5rem',
                      border: 'none',
                      backgroundColor: '#225E8B',
                      fontFamily: 'Gilroy-SemiBold',
                      color: 'white',
                      padding: '0.35rem 0.75rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      fontSize: '0.75rem',
                    }}
                    onClick={() => window.open('https://electrum.id/id', '_blank', 'rel=noopener noreferrer')}
                  >
                    {/* Lihat Selengkapnya &nbsp; <ArrowRightIcon /> */}
                    Lihat Selengkapnya
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* LOG OUT BUTTON */}
          {access_token && (
            <div style={{ paddingBottom: '1.5rem', display: 'flex', justifyContent: 'center' }}>
              <button
                style={{
                  cursor: 'pointer',
                  borderRadius: '5rem',
                  border: 'none',
                  backgroundColor: '#A0153E',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '1rem',
                  color: 'white',
                  padding: '0.5rem 2.5rem',
                }}
                onClick={handleLogOut}
              >
                Keluar
              </button>
            </div>
          )}

        </div>
      </div>
    </div>
  )
}

export default Home
