import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'

import BackArrowIcon from '../styles/icons/back-arrow'
import Winner1 from '../styles/images/winner1.jpg'
import Winner2 from '../styles/images/winner2.jpg'
import Winner3 from '../styles/images/winner3.jpg'
import Winner4 from '../styles/images/winner4.jpg'
import Winner5 from '../styles/images/winner5.jpg'
import Winner6 from '../styles/images/winner6.jpg'
import Winner7 from '../styles/images/winner7.jpg'

const Winners = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const image_styles = {
    minWidth: '100%',
    height: '100%',
    borderRadius: '0.625rem',
    scrollSnapAlign: 'start',
    objectFit: 'cover',
  }

  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef(null)
  const indicatorRefs = useRef([])

  const winnerList = [
    { img: Winner1 },
    { img: Winner2 },
    { img: Winner3 },
    { img: Winner4 },
    { img: Winner5 },
    { img: Winner6 },
    { img: Winner7 },
  ]

  useEffect(() => {
    const handleScroll = () => {
      const carousel = carouselRef.current;
      const scrollPosition = carousel.scrollLeft;
      const slideWidth = carousel.offsetWidth;
      const newIndex = Math.round(scrollPosition / slideWidth);
      setCurrentIndex(newIndex);
    };
    const carousel = carouselRef.current;
    carousel.addEventListener('scroll', handleScroll);
    return () => {
      carousel.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderWinnerCarousel = () => {
    return winnerList.map((item, index) => (
      <img key={index} alt='winner-img' src={item.img} style={image_styles} />
    ))
  }

  const handleIndicatorClick = (index) => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.scrollLeft = index * carousel.offsetWidth;
    }
  }

  return (
    <div
      style={{
        padding: isMobile ? 'initial' : '0 36.5%',
        backgroundColor: '#103856',
      }}
    >

      <Helmet>
        <title>Pemenang Sayembara | Electrum Berbagi</title>
      </Helmet>

      <div
        style={{
          height: '100vh',
          backgroundSize: 'cover',
          background: 'linear-gradient(180deg, rgba(194,251,255,1) 0%, rgba(92,170,214,1) 100%)',
          overflow: 'scroll',
        }}
      >

        {/* TOP SECTION */}
        <div
          style={{
            background: 'linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(16,56,86,1) 100%)',
            borderRadius: '0 0 1rem 1rem',
            display: 'flex',
            alignItems: 'center',
            padding: '0 1.75rem',
          }}
        >
          <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
            <BackArrowIcon />
          </div>
          <div
            style={{
              flex: 1,
              fontSize: '1.5rem',
              fontWeight: '500',
              color: 'white',
              fontFamily: 'Gilroy-ExtraBold',
              letterSpacing: '0.03rem',
              textAlign: 'center',
              padding: '1.75rem 0',
            }}
          >
            Pemenang Sayembara
          </div>
          <div style={{ width: '24px' }} />
        </div>

        {/* IMAGE SLIDER */}
        <div
          id='winner-carousel'
          ref={carouselRef}
          style={{
            margin: '1.25rem auto',
            backgroundColor: 'white',
            borderRadius: '0.625rem',
            width: '21.5rem',
            height: '29rem',
            display: 'flex',
            overflowX: 'scroll',
            scrollSnapType: 'x mandatory',
          }}
          onScroll={(e) => {
            const scrollPosition = e.target.scrollLeft
            const newIndex = Math.round(scrollPosition / window.innerWidth)
            setCurrentIndex(newIndex)
          }}
        >
          {renderWinnerCarousel()}
        </div>
        <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          // marginBottom: '1.25rem',
          marginTop: '-3rem',
        }}
      >
        {winnerList.map((slide, index) => (
          <div
            key={index}
            ref={(el) => (indicatorRefs.current[index] = el)}
            onClick={() => handleIndicatorClick(index)}
            style={{
              width: '30px',
              height: '7.5px',
              borderRadius: '50rem',
              backgroundColor: currentIndex === index ? '#1ECECA' : '#4F939D',
              margin: '0 3.5px',
              cursor: 'pointer',
            }}
          ></div>
        ))}
      </div>

        {/* TEXT CONTENT */}
        <div style={{
          backgroundColor: 'white',
          padding: '1.75rem',
          borderRadius: '0.625rem 0.625rem 0 0',
          marginTop: '2.75rem',
        }}>
          <div style={{ fontSize: '1.25rem', fontFamily: 'Gilroy-Extrabold', textAlign: 'center', marginBottom: '0.75rem' }}>
            Selamat!
          </div>
          <div style={{ fontSize: '0.825rem', fontFamily: 'Gilroy-Semibold', textAlign: 'center' }}>
            Restu Munajat<br />
            Abdul Rajab<br />
            Mochammad Yusuf<br />
            Davit Rizki Koma Hendra<br />
            Yudi Octavianus
            <br /><br />
            Masing-masing berhak mendapatkan hadiah senilai Rp 10 juta!
            <br /><br />
            Buat kamu yang belum beruntung, jangan berkecil hati.
            <br /><br />
            Kamu masih bisa mengikuti kompetisi <b>BARU (Bareng-bareng Lebih Seru)</b> dengan klik tombol di bawah ini
          </div>
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: '#225E8B',
              color: 'white',
              fontFamily: 'Gilroy-Bold',
              border: 'none',
              padding: '0.6rem',
              width: '100%',
              borderRadius: '7.5px',
              marginTop: '1.25rem',
            }}
            onClick={() => navigate('/campaign')}
          >
            IKUT DI SINI
          </button>
        </div>

      </div>
    </div>
  )
}

export default Winners
