import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './modal.css'

const Modal = ({ isOpen, closeBtn, onClose, children }) => {
  if (!isOpen) return null

  return (
    <div className="modal-overlay">
      <div className="modal">
        {closeBtn && (
          <button className="modal-close" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
