import React from "react"

function ArrowRightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#40C0C0"
        d="M2.898 11.763a.778.778 0 01-.231-.562c0-.216.077-.403.23-.56L7.41 6.007 2.882 1.36a.761.761 0 01-.215-.554c0-.221.077-.41.23-.569A.738.738 0 013.446 0c.21 0 .392.08.546.237l5.173 5.328a.56.56 0 01.131.206.722.722 0 01.038.237.722.722 0 01-.038.237.56.56 0 01-.131.206l-5.189 5.328a.712.712 0 01-.53.221.738.738 0 01-.547-.237z"
      ></path>
    </svg>
  )
}

export default ArrowRightIcon