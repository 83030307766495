import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { postCampaignSubmission } from '../apis/api'
import Loader from '../components/loader/loader'

import phoneIcon from '../styles/icons/phone.png'
import personIcon from '../styles/icons/person.png'
import BackArrowIcon from '../styles/icons/back-arrow'
import ArrowRightIcon from '../styles/icons/arrow-right'
import campaignSeparator from '../styles/images/campaign-separator.png'
import campaignBanner from '../styles/images/campaign-banner.png'

const Campaigns = () => {
  const access_token = localStorage.getItem('access_token')
  const user_data = localStorage.getItem('user_data')
  const parsed_user_data = JSON.parse(user_data)

  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [formData, setFormData] = useState({
    groupName: '',
    name1: '',
    phone1: '',
    name2: '',
    phone2: '',
    name3: '',
    phone3: '',
    name4: '',
    phone4: '',
    name5: '',
    phone5: '',
  })
  const [showTNC, setShowTNC] = useState(false)
  const [acceptTNC, setAcceptTNC] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => postCampaignSubmission(payload),
    onSuccess: () => {
      toast.success('Submission Successful!')
      navigate('/')
    },
    onError: (err) => {
      const errMessage = err.response.data.message
      toast.error(errMessage)
    },
  })

  const submitPayload = {
    access_token,
    email: parsed_user_data.email,
    groupName: formData.groupName,
    lists: [
      { fullName: formData.name1, phoneNumber: formData.phone1 },
      { fullName: formData.name2, phoneNumber: formData.phone2 },
      { fullName: formData.name3, phoneNumber: formData.phone3 },
      { fullName: formData.name4, phoneNumber: formData.phone4 },
      { fullName: formData.name5, phoneNumber: formData.phone5 },
    ]
  }

  const handleSubmit = () => {
    mutate(submitPayload)
  }

  return (
    <div
      style={{
        padding: isMobile ? 'initial' : '0 36.5%',
        backgroundColor: '#103856',
      }}
    >

      <Helmet>
        <title>THR Ronde 2 | Electrum Berbagi</title>
      </Helmet>

      {isLoading && <Loader />}
      <div
        style={{
          height: '100vh',
          backgroundSize: 'cover',
          background: 'linear-gradient(180deg, rgba(194,251,255,1) 0%, rgba(92,170,214,1) 100%)',
          overflow: 'scroll',
        }}
      >

        {/* TOP SECTION V2 */}
        <div
          style={{
            backgroundImage: `url("${campaignBanner}")`,
            backgroundSize: 'cover',
            borderRadius: '0 0 1rem 1rem',
            height: '21.5rem',
            imageResolution: '10000dpi',
          }}
        >
          <div
            style={{
              cursor: 'pointer',
              position: 'relative',
              padding: '2rem 1.5rem',
            }}
            onClick={() => navigate('/')}
          >
            <BackArrowIcon />
          </div>
        </div>

        {/* HOW TO SECTION */}
        <div style={{
          backgroundColor: 'white',
          padding: '1.75rem',
          margin: '-1.5rem 1.5rem 1.5rem 1.5rem',
          borderRadius: '0.625rem',
        }}>
          <div style={{ fontSize: '1.25rem', fontFamily: 'Gilroy-Extrabold', textAlign: 'center' }}>
            <span style={{ color: '#103856' }}>
              Buat nama regu yang unik,
            </span><br/>
            <span style={{ color: '#1ECECA' }}>
              dapatkan total Rp 30 juta!
            </span>
          </div>
          <img alt='campaign-separator' src={campaignSeparator} style={{ width: '100%', margin: '1rem 0' }} />
          <div style={{ fontSize: '0.75rem', color: '#103856' }}>
            <span style={{ fontFamily: 'Gilroy-Bold' }}>
              Ini caranya:
            </span>
            <ul style={{ fontFamily: 'Gilroy-Semibold', paddingLeft: '1.5rem', marginBottom: '1.5rem' }}>
              <li>Ajak 5 orang teman kamu menjadi sebuah regu/grup sehingga berisi 6 orang.</li>
              <li>Buat nama regu kamu yang paling unik dan sekreatif mungkin. Misal: Grup Hepi Terus, Grup Doyan Dolan, dll.</li>
              <li>Tulis nama regu unik kamu di formulir di bawah ini.</li>
              <li>Akan ada 5 regu unik yang berhak mendapatkan masing-masing Rp 6 juta.</li>
            </ul>
            <span style={{ fontFamily: 'Gilroy-Bold' }}>
              Syarat dan Ketentuan:
            </span>
            <ul style={{ fontFamily: 'Gilroy-Semibold', paddingLeft: '1.5rem' }}>
              <li>Kamu dan 5 orang teman kamu harus merupakan mitra driver Gojek di area Jakarta.</li>
              <li>Periode kompetisi BIRU hingga 10 Mei 2024.</li>
              <li>Pengumuman kompetisi BIRU akan dilakukan pada xxx melalui website komunitas Electrum Berbagi.</li>
              <li>Keputusan juri bersifat mutlak dan tidak dapat diganggu gugat.</li>
              <li>Info lebih lanjut, lihat selengkapnya dibawah.</li>
            </ul>
          </div>
        </div>

        {/* FORM SECTION */}
        <div
          style={{
            margin: '1.5rem',
            backgroundColor: 'white',
            padding: '1.25rem 1rem',
            borderRadius: '0.625rem',
          }}
        >
          <div style={{ textAlign: 'center', marginBottom: '1.25rem', marginTop: '0.5rem', fontWeight: '500', color: '#103856',fontFamily: 'Gilroy-Bold', fontSize: '1.125rem' }}>
            Isi Formulir untuk Bergabung!
          </div>

          {/* GROUP NAME */}
          <div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nama Regu/Grup
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='text'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='groupName'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ borderBottom: '2px dotted #103856', marginBottom: '0.75rem' }} />
          </div>

          {/* KANDIDAT 1 */}
          <div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nama Lengkap Teman ke-1
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='text'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='name1'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nomor Telefon Teman ke-1
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={phoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='number'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='phone1'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ borderBottom: '2px dotted #103856', marginBottom: '0.75rem' }} />
          </div>

          {/* KANDIDAT 2 */}
          <div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nama Lengkap Teman ke-2
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='text'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='name2'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nomor Telefon Teman ke-2
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={phoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='number'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='phone2'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ borderBottom: '2px dotted #103856', marginBottom: '0.75rem' }} />
          </div>

          {/* KANDIDAT 3 */}
          <div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nama Lengkap Teman ke-3
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='text'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='name3'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nomor Telefon Teman ke-3
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={phoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='number'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='phone3'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ borderBottom: '2px dotted #103856', marginBottom: '0.75rem' }} />
          </div>

          {/* KANDIDAT 4 */}
          <div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nama Lengkap Teman ke-4
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='text'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='name4'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nomor Telefon Teman ke-4
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={phoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='number'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='phone4'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ borderBottom: '2px dotted #103856', marginBottom: '0.75rem' }} />
          </div>

          {/* KANDIDAT 5 */}
          <div>
            <div style={{ marginBottom: '0.75rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nama Lengkap Teman ke-5
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='text'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='name5'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Nomor Telefon Teman ke-5
              </div>
              <div style={{ display: 'flex' }}>
                <img alt='lock' src={phoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
                <input
                  type='number'
                  style={{
                    border: '1.75px solid #ECECEC',
                    borderRadius: '7.5px',
                    margin: '5px 0px 5px 7.5px',
                    height: '33.5px',
                    width: '100%',
                    padding: '0 8.5px',
                  }}
                  name='phone5'
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* TNC CONTENT */}
          <div style={{ margin: '1.5rem 0' }}>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '0.5rem', cursor: 'pointer' }}
              onClick={() => setShowTNC(!showTNC)}
            >
              <div style={{ fontSize: '0.875rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
                Syarat dan Ketentuan
              </div>
              <div style={{ transform: showTNC ? 'rotate(270deg)' : 'rotate(90deg)', maxWidth: 'min-content' }}>
                <ArrowRightIcon />
              </div>
            </div>
            <div style={{ display: showTNC ? 'block' : 'none', fontSize: '0.75rem', marginBottom: '0.75rem',fontFamily: 'Gilroy-Medium' }}>
              Syarat dan ketentuan berikut ini menguraikan peraturan dan ketentuan untuk berpartisipasi dalam kompetisi BIRU (“Syarat & Ketentuan”) yang diselenggarakan oleh PT Energi Kreasi Bersama (“Electrum”).
              <br/><br/>
              Dengan mengisikan form ini, anda dianggap telah menerima dan menyetujui Syarat & Ketentuan ini.
              <br/><br/>
              <b>A. Aturan Umum</b>
              <br/><br/>
              1. Untuk berpartisipasi pada kompetisi BIRU, anda harus:
              <br/>
              a). mengisi formulir ini dengan benar dan lengkap.
              <br/>
              b). merupakan driver yang merupakan mitra aktif dari PT GoTo Gojek Tokopedia Tbk (“Mitra”).
              <br/><br/>
              2. Jangka waktu untuk berpartisipasi adalah sampai dengan tanggal 10 Mei 2024.
              <br/><br/>
              3. Dengan berpartisipasi dalam kompetisi BIRU, Anda menyatakan bahwa Anda berwenang untuk memberikan data-data dalam form ini dan menyetujui bahwa data-data yang dibagikan dalam form ini dapat digunakan Electrum untuk menghubungi Anda terkait dengan hal-hal yang berkenaan dengan kegiatan usaha Electrum, termasuk untuk keperluan pemberian hadiah, informasi mengenai produk Electrum dan bentuk penawaran atau promosi lainnya. Ketentuan ini tetap berlaku setelah kompetisi BIRU berakhir.
              <br/><br/>
              <b>B. Ketentuan Pemenang</b>
              <br/><br/>
              1. 5 regu pemenang akan dipilih berdasarkan urutan waktu pendaftaran formulir yaitu urutan ke-1, ke-10, ke-100, ke-1000, dan ke-10.000 dari daftar peserta yang sesuai dengan kriteria dan persyaratan yang ditentukan.
              <br/><br/>
              2. Hadiah berupa uang sebesar 6 juta rupiah atau berupa gratis sewa motor listrik Electrum H5/H3 dan gratis penukaran baterai motor listrik senilai setara atau bentuk lain berdasarkan kebijakan Electrum.
              <br/><br/>
              3. Nama-nama pemenang akan diumumkan pada akun Instagram @electrum.id. Pemenang juga akan dihubungi oleh tim CS Electrum melalui Whatsapp di bulan Mei 2024.
              <br/><br/>
              4. Sewaktu-waktu Electrum dapat mengubah Syarat & Ketentuan.
              <br/><br/>
              5. Segala keputusan pemenang sepenuhnya merupakan wewenang dari Electrum.
              <br/><br/>
              <u>Catatan: Untuk permintaan informasi atau pertanyaan lebih lanjut, dapat menghubungi Customer Service di nomor WA berikut. Waspada penipuan! Program ini tidak dipungut biaya!</u>
              <br/><br/>
              <b>Persetujuan Syarat dan Ketentuan</b>
              <br/>
              Dengan ini, saya sudah mengerti dan menyetujui Syarat dan Ketentuan kompetisi BIRU seperti yang tertera di atas
            </div>
            <div style={{ borderBottom: '2px solid #ECECEC', width: '100%' }} />
          </div>

          {/* AGREE TNC */}
          <div style={{ marginBottom: '1.25rem', display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" name="acceptTNC" value={acceptTNC} onClick={() => setAcceptTNC(!acceptTNC)} style={{ transform: 'scale(1.5)', marginRight: '1rem' }} />
            <label style={{ fontSize: '0.875rem', fontWeight: '500', color: '#888888', marginBottom: '0.25rem',fontFamily: 'Gilroy-Medium' }}>
              Saya Telah Membaca dan Menyetujui <b>Syarat dan Ketentuan</b>
            </label>
          </div>

          {/* BUTTON */}
          <button
            style={{
              cursor: acceptTNC ? 'pointer' : 'not-allowed',
              backgroundColor: acceptTNC ? '#225E8B' : '#EBEBEB',
              color: acceptTNC ? 'white' : '#A6A6A6',
              fontFamily: 'Gilroy-Bold',
              border: 'none',
              padding: '0.6rem',
              width: '100%',
              borderRadius: '7.5px',
              marginBottom: '0.5rem',
            }}
            onClick={handleSubmit}
            disabled={acceptTNC ? false : true}
          >
            Daftarkan Saya!
          </button>
        </div>

      </div>
    </div>
  )
}

export default Campaigns
