import React, { useEffect, useState } from 'react'
import PinInput from 'react-pin-input'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { postLogin } from '../apis/api/user'
import Loader from '../components/loader/loader'
import { Helmet } from 'react-helmet'

import { pinInputStyles } from '../css/input-styling'
import electrumLogo from '../styles/images/electrum_berbagi_logo_new.png'
import keyIcon from '../styles/icons/key.png'
import phoneIcon from '../styles/icons/phone.png'
import { useNavigate } from 'react-router-dom'

const SignIn = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [formData, setFormData] = useState({
    phoneNumber: '',
    pin: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => postLogin(payload),
    onSuccess: (response) => {
      queryClient.invalidateQueries('objects')
      setFormData({ phoneNumber: '', pin: '' })
      if (response.code === 200) {
        localStorage.setItem('access_token', response.access_token)
        localStorage.setItem('user_data', JSON.stringify(response.user_data))
      }
      toast.success('Login Successful!')
      navigate('/')
    },
    onError: (err) => {
      const errMessage = err.response.data.message
      toast.error(errMessage)
    },
  })

  const handleSubmit = () => {
    mutate(formData)
  }

  return (
    <div
      style={{
        padding: isMobile ? 'initial' : '0 36.5%',
        backgroundColor: '#103856',
      }}
    >

      <Helmet>
        <title>Masuk | Electrum Berbagi</title>
      </Helmet>

      {isLoading && <Loader />}
      <div
        style={{
          height: '100vh',
          backgroundSize: 'cover',
          background: 'linear-gradient(180deg, rgba(16,56,86,1) 0%, rgba(199,231,231,1) 100%)',
          overflow: 'scroll',
        }}
      >
        {/* LOGO */}
        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            padding: '3rem 2rem 2rem 2rem',
          }}
          onClick={() => navigate('/')}
        >
          <img alt='electrum_logo' src={electrumLogo} width={100} />
        </div>
        {/* WELCOME MSG */}
        <div
          style={{
            color: 'white',
            textAlign: 'center',
            fontFamily: 'Gilroy-SemiBold',
            fontWeight: 'bold',
            fontSize: '1.5rem',
          }}
        >
          Selamat Datang di <br/>
          Electrum Berbagi
        </div>
        {/* LOGIN FORM */}
        <div
          style={{
            margin: '1rem 2rem',
            backgroundColor: 'white',
            padding: '1.25rem 1rem',
            borderRadius: '15px',
          }}
        >
          <div style={{ textAlign: 'center', marginBottom: '1rem', marginTop: '0.5rem', fontWeight: '500', color: '#103856',fontFamily: 'Gilroy-Bold', fontSize: '1.125rem' }}>
            Masuk ke Electrum Berbagi
          </div>
          {/* PHONE NUMBER */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
              Nomor Telefon
            </div>
            <div style={{ display: 'flex' }}>
              <img alt='lock' src={phoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <input
                type='number'
                style={{
                  border: '1.75px solid #ECECEC',
                  borderRadius: '7.5px',
                  margin: '5px 0px 5px 7.5px',
                  height: '33.5px',
                  width: '100%',
                  padding: '0 8.5px',
                }}
                name='phoneNumber'
                onChange={handleChange}
              />
            </div>
          </div>
          {/* PIN */}
          <div style={{ marginBottom: '2rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem', fontFamily: 'Gilroy-Bold' }}>
              Kode PIN
            </div>
            <div style={{ display: 'flex', marginBottom: '0.25rem' }}>
              <img alt='lock' src={keyIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <PinInput
                length={6}
                initialValue=""
                secret
                onChange={(value, index) => {
                  setFormData({
                    ...formData,
                    pin: value,
                  })
                }}
                name="pin"
                type="numeric"
                inputMode="numeric"
                onComplete={(value, index) => {}}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                style={pinInputStyles.style}
                inputStyle={pinInputStyles.inputStyle}
                inputFocusStyle={pinInputStyles.inputFocusStyle}
              />
            </div>
            <div
              style={{ cursor: 'pointer', fontSize: '0.85rem', fontWeight: '500', color: '#40C0C0', marginBottom: '0.25rem', fontFamily: 'Gilroy-Bold' }}
              onClick={() => navigate('/forgot-pin')}
            >
              Lupa PIN?
            </div>
          </div>
          {/* BUTTON */}
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: '#225E8B',
              color: 'white',
              fontFamily: 'Gilroy-Bold',
              border: 'none',
              padding: '0.6rem',
              width: '100%',
              borderRadius: '7.5px',
              marginBottom: '1rem',
            }}
            onClick={handleSubmit}
          >
            Masuk
          </button>
          <div
            style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem', fontFamily: 'Gilroy-Bold', textAlign: 'center' }}
          >
            Pengguna Baru? {' '}
            <span style={{ color: '#40C0C0', cursor: 'pointer' }} onClick={() => navigate('/signup')}>
              Daftar disini!
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn
