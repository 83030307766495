import axios from "axios"
import { ELECTRUM_API_URL } from "../consts"

export const getWinnersList = async() => {
  const result = await axios.get(`${ELECTRUM_API_URL}/winners-list/all-candidates`)
  return result.data
}

export const postCampaignSubmission = async(payload) => {
  const result = await axios.post(`${ELECTRUM_API_URL}/campaign-submission/submit`, payload)
  return result.data
}
