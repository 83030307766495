export const pinInputStyles = {
  style: { padding: '5px' },
  inputStyle: {
    borderWidth: '1.75px',
    borderRadius: '7.5px',
    borderColor: '#ECECEC',
    color: '#40C0C0',
    fontSize: '1.5rem',
    width: '33.5px',
    height: '33.5px',
  },
  inputFocusStyle: {
    borderColor: '#40C0C0',
    color: '#40C0C0',
    fontSize: '1.5rem',
  },
}
