import React, { useEffect, useState } from 'react'
import PinInput from 'react-pin-input'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { postRegister } from '../apis/api/user'
import Loader from '../components/loader/loader'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'

import { pinInputStyles } from '../css/input-styling'
import signUpCampaignKV from '../styles/images/signup-campaign-kv.png'
import campaignSeparator from '../styles/images/campaign-separator.png'
import keyIcon from '../styles/icons/key.png'
import phoneIcon from '../styles/icons/phone.png'
import personIcon from '../styles/icons/person.png'
import mailIcon from '../styles/icons/mail.png'
import BackArrowIcon from '../styles/icons/back-arrow'

const SignUp = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    pin: '',
    confirmPin: '',
  })
  const [showTips, setShowTips] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => postRegister(payload),
    onSuccess: (response) => {
      queryClient.invalidateQueries('objects')
      setFormData({ fullName: '', email: '', phoneNumber: '', pin: '', confirmPin: '' })
      if (response.code === 201) {
        localStorage.setItem('access_token', response.access_token)
        localStorage.setItem('user_data', JSON.stringify(response.user_data))
      }
      toast.success('Account Registered Successfully!')
      navigate('/campaign')
    },
    onError: (err) => {
      const errMessage = err.response.data.message
      toast.error(errMessage)
    },
  })

  const isFilled = formData.fullName === ''
  || formData.email === ''
  || formData.phoneNumber === ''
  || formData.pin === ''
  || formData.confirmPin === ''

  const handleSubmit = () => {
    if (isFilled) {
      toast.warning('Semua kolom harus terisi!')
    } else if (formData.confirmPin !== formData.pin) {
      toast.warning('PIN tidak cocok!')
    } else {
      mutate(formData)
    }
  }

  return (
    <div
      style={{
        padding: isMobile ? 'initial' : '0 36.5%',
        backgroundColor: '#103856',
      }}
    >

      <Helmet>
        <title>Daftar & Ikuti BARU | Electrum Berbagi</title>
      </Helmet>

      {isLoading && <Loader />}
      <div
        style={{
          height: '100vh',
          backgroundSize: 'cover',
          background: 'linear-gradient(180deg, rgba(194,251,255,1) 0%, rgba(92,170,214,1) 100%)',
          overflow: 'scroll',
        }}
      >

        {/* TITLE */}
        <div
          style={{
            color: '#103856',
            textAlign: 'center',
            fontFamily: 'Gilroy-Bold',
            fontWeight: 'bold',
            fontSize: '1.5rem',
            margin: '2rem 2rem 0rem 2rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
            <BackArrowIcon />
          </div>
          BARU
          <div style={{ visibility: 'hidden' }}>
            <BackArrowIcon />
          </div>
        </div>

        {/* KV */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '1rem 2rem',
          }}
        >
          <img alt='electrum_logo' src={signUpCampaignKV} style={{ width: '100%' }} />
        </div>

        {/* TIPS */}
        <div style={{
          backgroundColor: 'white',
          padding: '0.675rem 1rem',
          margin: '0rem 2rem 1rem 2rem',
          borderRadius: '0.625rem',
        }}>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => setShowTips(!showTips)}
          >
            <div style={{ fontSize: '1rem', fontFamily: 'Gilroy-ExtraBold', color: '#103856', textAlign: 'center' }}>
              Gimana cara mainnya?
            </div>
            <div style={{ transform: showTips ? 'rotate(90deg) scale(0.75)' : 'rotate(270deg) scale(0.75)', maxWidth: 'min-content' }}>
              <BackArrowIcon width={20} height={20} />
            </div>
          </div>
          <div style={{ display: showTips ? 'block' : 'none' }}>
            <img alt='campaign-separator' src={campaignSeparator} style={{ width: '100%', margin: '1rem 0' }} />
            <div style={{ fontSize: '0.725rem', color: '#103856' }}>
              <span style={{ fontFamily: 'Gilroy-Bold' }}>
                Ini caranya:
              </span>
              <ul style={{ fontFamily: 'Gilroy-Semibold', paddingLeft: '1.5rem' }}>
                <li>Tulis nama dan no HP kamu dan 5 orang teman yang mau kamu ajak untuk menang bersama-sama, di formulir di bawah ini.</li>
                <li>Kamu dan 5 orang teman kamu harus merupakan mitra driver Gojek di area Jakarta.</li>
                <li>Akan ada 5 grup yang berhak mendapatkan masing-masing uang sebesar 6 juta rupiah atau berupa gratis sewa motor listrik Electrum dan gratis penukaran baterai motor listrik.</li>
                <li>Grup pemenang akan dipilih berdasarkan urutan waktu pendaftaran formulir yaitu urutan ke-1, ke-10, ke-100, ke-1000, dan ke-10.000 dari daftar grup peserta yang sesuai dengan kriteria dan persyaratan yang ditentukan.</li>
                <li>Periode kompetisi BARU hingga 10 Mei 2024.</li>
                <li>Pengumuman kompetisi BARU akan dilakukan pada bulan Mei 2024 melalui website komunitas Electrum Berbagi.</li>
                <li>Keputusan juri bersifat mutlak dan tidak dapat diganggu gugat.</li>
                <li>Info lebih lanjut, lihat Syarat dan Ketentuan selengkapnya di bagian bawah formulir ini.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* LOGIN FORM */}
        <div
          style={{
            margin: '0rem 2rem 1.5rem 2rem',
            backgroundColor: 'white',
            padding: '1.25rem 1rem',
            borderRadius: '15px',
          }}
        >
          <div style={{ textAlign: 'center', marginBottom: '1rem', marginTop: '0.5rem', fontWeight: '500', color: '#103856',fontFamily: 'Gilroy-Bold', fontSize: '1.125rem' }}>
            Daftar ke Electrum Berbagi
          </div>
          {/* FULL NAME */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
              Nama Lengkap <span style={{ color: 'red' }}>*</span>
            </div>
            <div style={{ display: 'flex' }}>
              <img alt='lock' src={personIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <input
                type='text'
                style={{
                  border: '1.75px solid #ECECEC',
                  borderRadius: '7.5px',
                  margin: '5px 0px 5px 7.5px',
                  height: '33.5px',
                  width: '100%',
                  padding: '0 8.5px',
                }}
                name='fullName'
                onChange={handleChange}
              />
            </div>
          </div>
          {/* EMAIL ADDRESS */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
              Alamat Email <span style={{ color: 'red' }}>*</span>
            </div>
            <div style={{ display: 'flex' }}>
              <img alt='lock' src={mailIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <input
                type='email'
                style={{
                  border: '1.75px solid #ECECEC',
                  borderRadius: '7.5px',
                  margin: '5px 0px 5px 7.5px',
                  height: '33.5px',
                  width: '100%',
                  padding: '0 8.5px',
                }}
                name='email'
                onChange={handleChange}
              />
            </div>
          </div>
          {/* PHONE NUMBER */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
              Nomor Telefon <span style={{ color: 'red' }}>*</span>
            </div>
            <div style={{ display: 'flex' }}>
              <img alt='lock' src={phoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <input
                type='number'
                style={{
                  border: '1.75px solid #ECECEC',
                  borderRadius: '7.5px',
                  margin: '5px 0px 5px 7.5px',
                  height: '33.5px',
                  width: '100%',
                  padding: '0 8.5px',
                }}
                name='phoneNumber'
                onChange={handleChange}
              />
            </div>
          </div>
          {/* PIN */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem', fontFamily: 'Gilroy-Bold' }}>
              Masukkan Kode PIN Baru <span style={{ color: 'red' }}>*</span>
            </div>
            <div style={{ display: 'flex', marginBottom: '0.25rem' }}>
              <img alt='lock' src={keyIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <PinInput
                length={6}
                initialValue=""
                secret
                onChange={(value, index) => {
                  setFormData({
                    ...formData,
                    pin: value,
                  })
                }}
                name="pin"
                type="numeric"
                inputMode="numeric"
                onComplete={(value, index) => {}}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                style={pinInputStyles.style}
                inputStyle={pinInputStyles.inputStyle}
                inputFocusStyle={pinInputStyles.inputFocusStyle}
              />
            </div>
          </div>
          {/* CONFIRM PIN */}
          <div style={{ marginBottom: '1rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem', fontFamily: 'Gilroy-Bold' }}>
              Konfirmasi Kode PIN Baru <span style={{ color: 'red' }}>*</span>
            </div>
            <div style={{ display: 'flex', marginBottom: '0.25rem' }}>
              <img alt='lock' src={keyIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <PinInput
                length={6}
                initialValue=""
                secret
                onChange={(value, index) => {
                  setFormData({
                    ...formData,
                    confirmPin: value,
                  })
                }}
                name="confirmPin"
                type="numeric"
                inputMode="numeric"
                onComplete={(value, index) => {}}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                style={pinInputStyles.style}
                inputStyle={pinInputStyles.inputStyle}
                inputFocusStyle={pinInputStyles.inputFocusStyle}
              />
            </div>
          </div>
          {/* BUTTON */}
          <button
            style={{
              cursor: !isFilled && !isLoading ? 'pointer' : 'not-allowed',
              backgroundColor: !isFilled && !isLoading ? '#225E8B' : '#EBEBEB',
              color: !isFilled && !isLoading ? 'white' : '#A6A6A6',
              fontFamily: 'Gilroy-Bold',
              border: 'none',
              padding: '0.6rem',
              width: '100%',
              borderRadius: '7.5px',
              marginBottom: '0.5rem',
            }}
            onClick={handleSubmit}
            disabled={!isFilled && !isLoading ? false : true}
          >
            { !isLoading ? 'Daftar' : 'Loading...' }
          </button>
          <div
            style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem', fontFamily: 'Gilroy-Bold', textAlign: 'center' }}
          >
            Pengguna Lama? {' '}
            <span style={{ color: '#40C0C0', cursor: 'pointer' }} onClick={() => navigate('/signin')}>
              Masuk disini!
            </span>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SignUp
